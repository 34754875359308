import { styled } from "@jamiegardiner/cosmos-system"
import React from "react"
import Plx from "react-plx"

const backgroundData = [
  {
    start: "self",
    end: "#directorTrigger",
    startOffset: "100vh",
    duration: "100vh",
    properties: [
      {
        startValue: "rgba(0,0,0,0)",
        endValue: "rgba(0,0,0,0.4)",
        property: "backgroundColor",
      },
    ],
  },
  {
    start: "#fade2Trigger",
    duration: "100vh",
    properties: [
      {
        startValue: "rgba(0,0,0,0.4)",
        endValue: "rgba(0,0,0,1)",
        property: "backgroundColor",
      },
    ],
  },
  {
    start: "#fade3Trigger",
    duration: "100vh",
    properties: [
      {
        startValue: "rgba(0,0,0,1)",
        endValue: "rgba(0,0,0,0.4)",
        property: "backgroundColor",
      },
    ],
  },
]

const StyledCover = styled(Plx)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-width: 100vh;
  min-height: 100vh;
  z-index: -99;
`

export const Cover: React.FC = () => (
  <StyledCover parallaxData={backgroundData} />
)
