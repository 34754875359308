import { Box, Flex } from "@jamiegardiner/cosmos-components"
import { css, styled } from "@jamiegardiner/cosmos-system"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import React from "react"
import { SRLWrapper } from "simple-react-lightbox"

import { Heading } from "./Heading"
import { Section } from "./Section"

const ThumbnailContainer = styled(Box)`
  cursor: zoom-in;
  ${css({
    p: 2,
  })}
`

export const Gallery = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 750, maxHeight: 500, quality: 20) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Section>
      <Heading level="h1">Gallery</Heading>
      <SRLWrapper options={{ buttons: { showDownloadButton: false } }}>
        <Flex
          justifyContent={["space-between", "space-between"]}
          flexWrap="wrap"
          mt={5}
          mx="auto"
        >
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            edges.map(({ node }) => {
              const { childImageSharp } = node
              return (
                <ThumbnailContainer
                  boxShadow="medium"
                  width={[1 / 2, 1 / 4]}
                  mx="auto"
                  key={childImageSharp.fluid.src}
                >
                  <GatsbyImage fluid={childImageSharp.fluid} />
                </ThumbnailContainer>
              )
            })
          }
        </Flex>
      </SRLWrapper>
    </Section>
  )
}
