import { Heading as DSHeading } from "@jamiegardiner/cosmos-components"
import { css, styled } from "@jamiegardiner/cosmos-system"

export const Heading = styled(DSHeading)`
  ${css({
    fontSize: [2, 5],
    fontFamily: "heading",
    color: "palette.white",
    fontStyle: "italic",
    textAlign: "center",
    lineHeight: 1.5,
    mb: [5, 9],
    textShadow: "small",
  })}
`
