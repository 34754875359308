import { css, styled } from "@jamiegardiner/cosmos-system"
import React from "react"
import Plx from "react-plx"

const fadeIn = [
  {
    start: "self",
    startOffset: 200,
    duration: 250,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
]

export const StyledFadeIn = styled(Plx)`
  opacity: 0;
  margin-bottom: 25vh;
  ${css({
    marginBottom: ["10vh", "25vh"],
  })}
`
export const FadeIn: React.FC = ({ children }) => (
  <StyledFadeIn parallaxData={fadeIn}>{children}</StyledFadeIn>
)
