import { Box, Container } from "@jamiegardiner/cosmos-components"
import React from "react"

import { FadeIn } from "./FadeIn"

export const Section: React.FC = ({ children }) => (
  <FadeIn>
    <Box
      bg="rgba(0,0,0,0.4)"
      py={[8, 12]}
      px={6}
      fontSize={3}
      mb={9}
      boxShadow="medium"
    >
      <Container>{children}</Container>
    </Box>
  </FadeIn>
)
