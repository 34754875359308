import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Flex, Text } from "@jamiegardiner/cosmos-components"
import { css, styled } from "@jamiegardiner/cosmos-system"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ReactHtmlParser from "react-html-parser"
import { Link } from "react-scroll"

import { Heading } from "./Heading"
import { Section } from "./Section"
import { TextBlock } from "./TextBlock"

const query = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "synopsis" } }) {
      html
      frontmatter {
        title
        footer
      }
    }
    file(name: { eq: "jp" }) {
      childImageSharp {
        fluid(quality: 20) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const StyledLink = styled(Link)`
  text-align: center;
  cursor: pointer;
  font-style: italic;
  font-weight: bold;
  ${css({
    color: "palette.white",
    fontSize: 1,
  })}
  &:hover {
    text-decoration: none;
  }
`

export const Synopsis: React.FC = () => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
  } = useStaticQuery(query)
  return (
    <Section>
      <Heading level="h1" textAlign="center">
        {title}
      </Heading>
      <TextBlock>{ReactHtmlParser(html)}</TextBlock>
      <Flex alignItems="center" color="white" flexDirection="column" mt={10}>
        <StyledLink to="fade2Trigger" smooth="true">
          <Box mb={4}>
            <Text textShadow="small">Watch the Trailers</Text>
          </Box>
          <Text textShadow="small">
            <FontAwesomeIcon icon={faChevronDown} size="2x" />
          </Text>
        </StyledLink>
      </Flex>
    </Section>
  )
}
