import { styled } from "@jamiegardiner/cosmos-system"
import React from "react"

import bg from "../media/bg.mp4"

const StyledVideo = styled("video")`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -100;
`

export const FullPageVideo: React.FC = () => (
  <StyledVideo autoPlay playsInline muted loop>
    <source src={bg} type="video/mp4" />
  </StyledVideo>
)
