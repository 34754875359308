import { Box } from "@jamiegardiner/cosmos-components"
import React, { useState } from "react"
import ReactPlayer from "react-player"
import VisibilitySensor from "react-visibility-sensor"

export const Trailer: React.FC<{ url: string; isPlaying: boolean }> = ({
  url,
  isPlaying,
}) => {
  const [state, setState] = useState<{
    isPlaying: boolean
    isDirty?: boolean
  }>({
    isPlaying,
    isDirty: false,
  })
  return (
    <Box height={["100%", "70vh"]} boxShadow={["none", "medium"]}>
      <VisibilitySensor
        onChange={(isVisible) => {
          if (state.isDirty) setState({ isPlaying: isVisible, isDirty: true })
        }}
      >
        <ReactPlayer
          playing={state.isPlaying}
          onPause={() => setState({ isPlaying: false })}
          onEnded={() => setState({ isPlaying: false })}
          onPlay={() => setState({ isPlaying: true, isDirty: true })}
          playsinline
          loop
          controls
          url={url}
          width="100%"
          height="100%"
        />
      </VisibilitySensor>
    </Box>
  )
}
