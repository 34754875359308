import { css, styled } from "@jamiegardiner/cosmos-system"
import React from "react"
import Plx from "react-plx"

import TitleSvg from "../media/title_3.svg"

const titleData = [
  {
    start: "self",
    duration: "30vh",
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
      {
        startValue: 1,
        endValue: 0,
        property: "scale",
      },
    ],
  },
]

const TitlePlx = styled(Plx)`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50vh;
  width: 100%;
`

const StyledTitle = styled(TitleSvg)`
  transform: translateY(-50%);
  ${css({
    width: ["80%", "50%"],
  })}
`

export const Title: React.FC = () => (
  <TitlePlx parallaxData={titleData}>
    <StyledTitle />
  </TitlePlx>
)
