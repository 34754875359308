import { Box, Button, Flex } from "@jamiegardiner/cosmos-components"
import { css, styled } from "@jamiegardiner/cosmos-system"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import React, { useState } from "react"
import ReactHtmlParser from "react-html-parser"

import { Heading } from "./Heading"
import { Section } from "./Section"
import { TextBlock } from "./TextBlock"

const query = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "about" } }) {
      html
      frontmatter {
        title
        footer
      }
    }
    file(name: { eq: "jp" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ReadMoreButton = styled(Button)`
  cursor: pointer;
  ${css({
    bg: "palette.black",
    fontSize: 1,
    color: "palette.white",
    p: 4,
    boxShadow: "small",
  })};

  &:hover {
    text-decoration: none;
    ${css({
      bg: "palette.gray.8",
    })};
  }
`

const SubHeading = styled(Heading)`
  ${css({
    fontSize: [1, 2],
    textAlign: "right",
  })};
`

const TruncatedBlock: React.FC = ({ children }) => {
  const [toggle, setToggle] = useState<boolean>(false)
  const firstChild = React.Children.toArray(children)
  const collapsedContent = firstChild.splice(0, 2)
  return (
    <>
      <Box mb={6}>{toggle ? children : collapsedContent}</Box>
      <ReadMoreButton as="a" onClick={() => setToggle(!toggle)}>
        {toggle ? "Read less" : "Read more"}
      </ReadMoreButton>
    </>
  )
}

export const Story: React.FC = () => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title, footer },
    },
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <Section>
      <Heading level="h1" fontSize={[2, 5]} fontStyle="italic">
        {title}
      </Heading>
      <TextBlock textAlign="justify">
        <TruncatedBlock>{ReactHtmlParser(html)}</TruncatedBlock>
      </TextBlock>
      <Flex mt={6} justifyContent="flex-end" alignContent="flex-end">
        <Box width={[1, 1 / 2]}>
          <Flex flexDirection="column">
            <Box boxShadow="medium" mb={5}>
              <GatsbyImage fluid={fluid} />
            </Box>
            <Box>
              <SubHeading level="h2">{footer}</SubHeading>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Section>
  )
}
