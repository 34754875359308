import { Box, Flex } from "@jamiegardiner/cosmos-components"
import { css, styled } from "@jamiegardiner/cosmos-system"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import React, { useState } from "react"
import { scroller } from "react-scroll"

import { Heading } from "./Heading"
import { Section } from "./Section"
import { Trailer } from "./Trailer"

const query = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "video-thumbs" } }
      sort: { fields: childImageSharp___fields___order, order: ASC }
    ) {
      nodes {
        id
        childImageSharp {
          fluid(maxWidth: 320, maxHeight: 180, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fields {
            url
            tag
          }
        }
      }
    }
  }
`

const SubHeading = styled(Heading)`
  ${css({
    fontSize: [1, 2],
    mb: 0,
  })}
`

const Thumbnail = styled(Box)`
  ${css({
    boxShadow: ["small", "medium"],
  })}

  &:hover {
    cursor: pointer;
  }
`

export const TrailersBlock: React.FC = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(query)
  const defaultThumb = nodes[0]
  const {
    childImageSharp: {
      fields: { url, tag },
    },
  } = defaultThumb
  const [state, setState] = useState<{
    tag: string
    url: string
    playing: boolean
  }>({ url, tag, playing: false })

  const onThumbClick = (video: { tag: string; url: string }) => {
    setState({ ...video, playing: false })
    scroller.scrollTo("trailerAnchor", { smooth: true, offset: -43 })
  }
  return (
    <Section>
      <Flex flexDirection="column">
        <Box>
          <Heading level="h1">Videos</Heading>
          <div id="trailerAnchor" />
          <SubHeading>{state.tag}</SubHeading>
        </Box>
        <Box mb={5}>
          <Trailer url={state.url} isPlaying={state.playing} />
        </Box>
        <Box>
          <Flex justifyContent="space-between" flexWrap="wrap" mt={5} mx="auto">
            {nodes.map(
              ({
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                id,
                childImageSharp: {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                  // @ts-ignore
                  fluid,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                  // @ts-ignore
                  fields: { url: videoUrl, tag: videoTag },
                },
              }) => {
                return (
                  <Thumbnail
                    width={[1 / 8]}
                    key={id}
                    onClick={
                      () => onThumbClick({ url: videoUrl, tag: videoTag })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    <GatsbyImage fluid={fluid} />
                  </Thumbnail>
                )
              }
            )}
          </Flex>
        </Box>
      </Flex>
      <Box style={{ clear: "both" }} />
    </Section>
  )
}
