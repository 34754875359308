import { Box, Container, Text } from "@jamiegardiner/cosmos-components"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ReactHtmlParser from "react-html-parser"

const query = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "footer" } }) {
      html
    }
  }
`

export const Footer = () => {
  const {
    markdownRemark: { html },
  } = useStaticQuery(query)
  return (
    <Box bg="palette.black" pb={45} mt={10} as="footer" pt={25}>
      <Container px={[4, 9]}>
        <Box textAlign="center" maxWidth="900px" mx="auto">
          <Box color="palette.gray.4" fontSize={[1, 2]} textShadow="small">
            <Text>
              <Text fontWeight="bold">PERRY PRODUCTIONS</Text>
              <span> presents </span>
            </Text>
            <Text fontWeight="bold">
              &ldquo;GREAT&nbsp;WHITE&nbsp;ENCOUNTERS&rdquo;
            </Text>
          </Box>
          <Text color="palette.gray.4" fontSize={[0, 1]}>
            {ReactHtmlParser(html)}
          </Text>
        </Box>
      </Container>
    </Box>
  )
}
