import { css, styled } from "@jamiegardiner/cosmos-system"
import React from "react"
import Plx from "react-plx"

import DirectorSvg from "../media/director_1.svg"

const directorData = [
  {
    start: "self",
    startOffset: "-125vh",
    duration: "40vh",
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
      {
        startValue: 1,
        endValue: 1.5,
        property: "scale",
      },
    ],
  },
  {
    start: "#directorTrigger",
    duration: "35vh",
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
      {
        startValue: 1.5,
        endValue: 0,
        property: "scale",
      },
    ],
  },
]

const DirectorPlx = styled(Plx)`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50vh;
  width: 100%;
  opacity: 0;
`

const StyledDirector = styled(DirectorSvg)`
  transform: translateY(-50%);
  ${css({
    width: ["50%", "30%"],
  })}
`

export const Director: React.FC = () => (
  <DirectorPlx parallaxData={directorData}>
    <StyledDirector />
  </DirectorPlx>
)
