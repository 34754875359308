import { Box, Flex } from "@jamiegardiner/cosmos-components"
import { css, styled } from "@jamiegardiner/cosmos-system"
import React from "react"
import Plx from "react-plx"
import { Link } from "react-scroll"

const NavContainer = styled(Plx)`
  opacity: 0;
  width: 100%;
  top: 0;
  position: fixed;
  ${css({
    px: 6,
    py: 8,
    bg: "palette.black",
    color: "grey",
  })}
  z-index: 100
`

const navData = [
  {
    start: "self",
    startOffset: "150vh",
    duration: "75vh",
    properties: [
      {
        startValue: 0,
        endValue: 0.9,
        property: "opacity",
      },
    ],
  },
]

const NavLink = styled("span")`
  cursor: pointer;
  ${css({
    color: "palette.white",
    fontSize: [0, 1],
  })};
  &:hover {
    text-decoration: none;
  }
`

const NavItemContainer: React.FC = ({ children }) => (
  <Flex flexDirection="row" alignItems="baseline" justifyContent="flex-end">
    {children}
  </Flex>
)
const NavItem: React.FC<{ text: string; to: string }> = ({ text, to }) => {
  return (
    <Link to={to} smooth offset={0}>
      <Box mr={6}>
        <NavLink>{text}</NavLink>
      </Box>
    </Link>
  )
}

export const Nav: React.FC = () => (
  <NavContainer parallaxData={navData}>
    <NavItemContainer>
      <NavItem text="Synopsis" to="directorTrigger" />
      <NavItem text="Videos" to="fade2Trigger" />
      <NavItem text="Gallery" to="fade3Trigger" />
      <NavItem text="Background" to="story" />
    </NavItemContainer>
  </NavContainer>
)
