import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box } from "@jamiegardiner/cosmos-components"
import { styled } from "@jamiegardiner/cosmos-system"
import React from "react"
import Plx from "react-plx"

const data = [
  {
    start: 0,
    duration: "90vh",
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
]

const Pulse = styled(Box)`
  animation: pulse 1s infinite;
`

const StyledBox = styled.div`
  margin: 1rem auto;
  text-align: center;
  align-items: center;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
`

export const ScrollIndicator = () => (
  <StyledBox>
    <Plx parallaxData={data}>
      <Pulse color="grey">
        <FontAwesomeIcon icon={faChevronDown} size="3x" />
      </Pulse>
    </Plx>
  </StyledBox>
)
