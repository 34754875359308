import { Text } from "@jamiegardiner/cosmos-components"
import React from "react"

export const TextBlock: React.FC<{ textAlign?: string }> = ({
  textAlign = "center",
  children,
}) => (
  <Text
    as="div"
    fontSize={[1, 2]}
    color="palette.white"
    lineHeight={1.7}
    textAlign={textAlign}
  >
    {children}
  </Text>
)
