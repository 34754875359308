import { graphql, useStaticQuery } from "gatsby"
import keywordExtractor from "keyword-extractor"
import React from "react"
import { Helmet } from "react-helmet"

export const Seo: React.FC = () => {
  const {
    site: {
      siteMetadata: { author, desc, siteName, title, siteUrl },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            desc
            siteName
            title
            siteUrl
          }
        }
      }
    `
  )

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          name="viewport"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          rel="stylesheet"
        />
        <title>{title}</title>
        <meta content={desc} name="description" />
        <meta content={desc} property="og:description" />
        <meta content={title} property="og:title" />
        <meta content="website" property="og:type" />
        <meta content={siteUrl} property="og:url" />
        <meta content={siteName} property="og:site_name" />
        <meta content="summary" name="twitter:card" />
        <meta content={author} name="twitter:creator" />
        <meta content={title} name="twitter:title" />
        <meta content={desc} name="twitter:description" />
        <meta content="index,follow" name="robots" />
        <meta
          content={keywordExtractor
            // eslint-disable-next-line @typescript-eslint/camelcase, camelcase
            .extract(desc, { remove_duplicates: true })
            .join(",")}
          name="keywords"
        />
      </Helmet>
    </>
  )
}
