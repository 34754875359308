import { styled } from "@jamiegardiner/cosmos-system"
import React from "react"
import SimpleReactLightbox from "simple-react-lightbox"

import {
  Director,
  Footer,
  FullPageVideo,
  Gallery,
  Nav,
  ScrollIndicator,
  Seo,
  Story,
  Synopsis,
  Title,
} from "../components"
import { TrailersBlock } from "../components/TrailersBlock"

const TopBuffer = styled.div`
  margin-top: 150vh;
`

const Index: React.FC = () => {
  return (
    <SimpleReactLightbox>
      <Seo />
      <Nav />
      <ScrollIndicator />
      <TopBuffer />
      <FullPageVideo />
      <Title />
      <Director />
      <div id="directorTrigger" />
      <Synopsis />
      <div id="fade2Trigger" />
      <TrailersBlock />
      <div id="fade3Trigger" />
      <Gallery />
      <div id="story" />
      <Story />
      <Footer />
    </SimpleReactLightbox>
  )
}

export default Index
